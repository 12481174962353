import React, {useState, useEffect} from "react"
import Select, {Option} from "rc-select"
import classnames from "classnames"
import EventListing from "Layout/Events/EventListing/EventListing"
import "./EventListingWithFilter.scss"

const EventListingWithFilter = ({events}) => {
  const [initialItems] = useState(events.edges ? events.edges : [])
  const [items, setItems] = useState(initialItems)
  const [filterTypeActive, setFilterTypeActive] = useState(false)
  const [filterLocationActive, setFilterLocationActive] = useState(false)
  const [filterYearActive, setFilterYearActive] = useState(false)
  const [filterTypeSelected, setFilterTypeSelected] = useState("")
  const [filterLocationSelected, setFilterLocationSelected] = useState("")
  const [filterYearSelected, setFilterYearSelected] = useState("")

  const currentDate = `${new Date().getFullYear()}${(new Date().getMonth() + 1).toString().length > 1 ? ((new Date().getMonth() + 1)) : (`0${(new Date().getMonth() + 1)}`)}${new Date().getDate().toString().length > 1 ? (new Date().getDate()) : (`0${new Date().getDate()}`)}`

  const locationsOptionsTemp = new Set()
  const yearsOptionsTemp = new Set()
  const typesOptionsTemp = new Set()

  const allItemsString = "all"
  initialItems.sort((a,b) => (a.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "years")[0].name > b.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "years")[0].name) ? 1 : ((b.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "years")[0].name > a.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "years")[0].name) ? -1 : 0)).reverse()

  // eslint-disable-next-line array-callback-return
  initialItems.map(item => {
    item.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "locations").map(location => (
      locationsOptionsTemp.add(location.name)
    ))
    yearsOptionsTemp.add((item.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "years"))[0].name)
    typesOptionsTemp.add((item.node.categories_names.filter(itemAttribute => itemAttribute.taxonomy === "types"))[0].name)
  })

  // non-lecture events
  const filteredEvents = initialItems.filter(event => {
    const eventType = event.node.categories_names.filter(item => item.taxonomy === "types").length ? event.node.categories_names.filter(item => item.taxonomy === "types").map(item => item.name).toString() : ""
    return eventType !== "" && eventType !== "Lecture"
  })

  const handleDateFormatting = (year, month, day, time, period) => {
    let dateFormatted = ""
    const yearFormatted = year
    let monthFormatted = ""
    const dayFormatted = day.replace(",", "").length > 1 ? day.replace(",", "") : `0${day.replace(",", "")}`

    switch (month) {
      case "January":
        monthFormatted = "01"
        break

      case "February":
        monthFormatted = "02"
        break

      case "March":
        monthFormatted = "03"
        break

      case "April":
        monthFormatted = "04"
        break

      case "May":
        monthFormatted = "05"
        break

      case "June":
        monthFormatted = "06"
        break

      case "July":
        monthFormatted = "07"
        break

      case "August":
        monthFormatted = "08"
        break

      case "September":
        monthFormatted = "09"
        break

      case "October":
        monthFormatted = "10"
        break

      case "November":
        monthFormatted = "11"
        break

      case "December":
        monthFormatted = "12"
        break

      default:
        break
    }

    // time
    if(time && period) {
      const isPM = period === "pm"
      const timeFormatted = isPM ? parseFloat(time.split(":")[0]) + 12 : parseFloat(time.split(":")[0])

      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}T${timeFormatted}${time.split(":")[1]}00Z`
    } else {
      dateFormatted = `${yearFormatted + monthFormatted + dayFormatted}`
    }

    return dateFormatted
  }

  const sortedEvents = filteredEvents.slice(0).sort((a, b) => {
    const aNodeStartDate = a.node.acf.event.event_date_info.event_date_info_start_date ? a.node.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
    const aNodeStartTime = a.node.acf.event.event_date_info.event_date_info_start_time ? a.node.acf.event.event_date_info.event_date_info_start_time.split(" ") : false
    const bNodeStartDate = b.node.acf.event.event_date_info.event_date_info_start_date ? b.node.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
    const bNodeStartTime = b.node.acf.event.event_date_info.event_date_info_start_time ? b.node.acf.event.event_date_info.event_date_info_start_time.split(" ") : false

    const aNodeStartFormatted = aNodeStartDate ? handleDateFormatting(aNodeStartDate[2], aNodeStartDate[0], aNodeStartDate[1], aNodeStartTime ? aNodeStartTime[0] : false, aNodeStartTime ? aNodeStartTime[1] : false) : false
    const bNodeStartFormatted = bNodeStartDate ? handleDateFormatting(bNodeStartDate[2], bNodeStartDate[0], bNodeStartDate[1], bNodeStartTime ? bNodeStartTime[0] : false, bNodeStartTime ? bNodeStartTime[1] : false) : false

    return (
      aNodeStartFormatted < bNodeStartFormatted ? 1 : -1
    )
  })

  const upcomingEvents = sortedEvents.slice(0).filter(event => {
    const eventDateInfoStartDate = event.node.acf.event.event_date_info.event_date_info_start_date ? event.node.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
    const eventDateInfoStartTime = event.node.acf.event.event_date_info.event_date_info_start_time ? event.node.acf.event.event_date_info.event_date_info_start_time.split(" ") : false

    const eventDateStartFormatted = eventDateInfoStartDate ? handleDateFormatting(eventDateInfoStartDate[2], eventDateInfoStartDate[0], eventDateInfoStartDate[1], eventDateInfoStartTime ? eventDateInfoStartTime[0] : false, eventDateInfoStartTime ? eventDateInfoStartTime[1] : false).substring(0, 8) : false

    return (eventDateStartFormatted > currentDate ? eventDateStartFormatted : null)
  })

  const pastEvents = sortedEvents.slice(0).filter(event => {
    const eventDateInfoStartDate = event.node.acf.event.event_date_info.event_date_info_start_date ? event.node.acf.event.event_date_info.event_date_info_start_date.split(" ") : false
    const eventDateInfoStartTime = event.node.acf.event.event_date_info.event_date_info_start_time ? event.node.acf.event.event_date_info.event_date_info_start_time.split(" ") : false

    const eventDateStartFormatted = eventDateInfoStartDate ? handleDateFormatting(eventDateInfoStartDate[2], eventDateInfoStartDate[0], eventDateInfoStartDate[1], eventDateInfoStartTime ? eventDateInfoStartTime[0] : false, eventDateInfoStartTime ? eventDateInfoStartTime[1] : false).substring(0, 8) : false

    return (((eventDateStartFormatted < currentDate)) ? eventDateStartFormatted : null)
  })

  const locationsOptions = Array.from(locationsOptionsTemp).sort()
  locationsOptions.unshift(allItemsString)

  const yearsOptions = Array.from(yearsOptionsTemp).sort()
  yearsOptions.unshift(allItemsString)

  const typesOptions = Array.from(typesOptionsTemp).filter(type => type !== "Lecture").sort()
  typesOptions.unshift(allItemsString)

  const filterItems = () => {
    // let updatedList = sortedEvents
    let updatedList = pastEvents

    updatedList = updatedList.filter(item => {
      const locationQueryString = item.node.categories_names.filter(itemTaxonomy => itemTaxonomy.taxonomy === "locations").map(location => (
        location.name.toLowerCase()
      ))
      const yearQueryString = item.node.categories_names.filter(itemTaxonomy => itemTaxonomy.taxonomy === "years")[0].name
        .toString()
        .toLowerCase()
      const typeQueryString = item.node.categories_names.filter(itemTaxonomy => itemTaxonomy.taxonomy === "types")[0].name
        .toString()
        .toLowerCase()

      return (
        (filterLocationSelected === "" ||
          locationQueryString.includes(filterLocationSelected.toLowerCase())) &&
        (filterYearSelected === "" ||
          yearQueryString.search(filterYearSelected.toLowerCase()) !== -1) &&
        (filterTypeSelected === "" ||
          typeQueryString.search(filterTypeSelected.toLowerCase()) !== -1)
      )
    })
    setItems(updatedList)
  }

  const onChangeOption = (e, filterOption) => {
    let value
    if (e && e.target) {
      // eslint-disable-next-line prefer-destructuring
      value = e.target.value
    } else {
      value = e
    }

    if (filterOption === "filterType") {
      setFilterTypeActive(value !== "all")
      setFilterTypeSelected(value !== "all" ? value : "")
    }
    if (filterOption === "filterYear") {
      setFilterYearActive(value !== "all")
      setFilterYearSelected(value !== "all" ? value : "")
    }
    if (filterOption === "filterLocation") {
      setFilterLocationActive(value !== "all")
      setFilterLocationSelected(value !== "all" ? value : "")
    }
  }

  const renderFilterControl = () => {
    return (
      <>
        <div className="col-sm-4 col-md-3 col-lg-2">
          <Select
            className={classnames("btn", {
              "is-active" : filterYearActive
            })}
            showSearch={false}
            placeholder="Date"
            inputIcon="↓"
            onChange={e => onChangeOption(e, "filterYear")}
          >
            {yearsOptions.map(yearOption => (
              <Option
                key={yearOption}
                className={classnames({
                "rc-select-dropdown-menu-item-selected" : yearOption === filterYearSelected,
              })}
              >
                {yearOption}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-sm-4 col-md-3 col-lg-2">
          <Select
            className={classnames("btn", {"is-active" : filterLocationActive})}
            placeholder="Location"
            showSearch={false}
            inputIcon="↓"
            onChange={e => {onChangeOption(e, "filterLocation")}}
          >
            {locationsOptions.map(locationOption => (
              <Option
                key={locationOption}
                className={classnames({
                  "rc-select-dropdown-menu-item-selected" : locationOption === filterLocationSelected,
                })}
              >
                {locationOption}
              </Option>
            ))}
          </Select>
        </div>
        <div className="col-sm-4 col-md-3 col-lg-2">
          <Select
            className={classnames("btn", {
              "is-active" : filterTypeActive,
            })}
            showSearch={false}
            placeholder="Type"
            inputIcon="↓"
            onChange={e => onChangeOption(e, "filterType")}
          >
            {typesOptions.map(typeOption => (
              <Option
                key={typeOption}
                className={classnames({
                  "rc-select-dropdown-menu-item-selected" : typeOption === filterTypeSelected,
                })}
              >
                {typeOption}
              </Option>
            ))}
          </Select>
        </div>
      </>
    )
  }

  useEffect(() => {
    filterItems()
  }, [filterTypeSelected, filterLocationSelected, filterYearSelected])

  return (
    <>
      {
        upcomingEvents && upcomingEvents.length > 0 ? (
          <div className="container-fluid bg-white mb-3 event-list-upcoming">
            <div className="container">
              <div className="row">
                <h2 className="bracket-text col-12 mt-2 mt-lg-1 mb-1">Upcoming</h2>
              </div>
              <EventListing
                events={upcomingEvents}
                classes="row"
              />
            </div>
          </div>
        ) : null
      }
      <div className="event-filter-section section container-fluid">
        <div className="container">
          <div className="row event-filter d-flex flex-wrap justify-content-start justify-content-md-end mb-2">
            {renderFilterControl()}
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white event-list-past">
        <div className="container">
          {
            upcomingEvents && upcomingEvents.length > 0 ? (
              <div className="row">
                <h2 className="bracket-text col-12 mt-2 mt-lg-1 mb-1">Past</h2>
              </div>
            ) : null
          }
          <EventListing
            events={items}
            classes="row"
          />
        </div>
      </div>
    </>
  )
}

export default EventListingWithFilter
